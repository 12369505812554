import { format } from 'date-fns';

import { CertificateConfig } from '../types';

export default function generateDescription(config: CertificateConfig): string {
  const replacementPossessive = new RegExp('%pronoun_possessive%', 'g');
  const replacementSubjective = new RegExp('%pronoun_subjective%', 'g');
  const replacementObjective = new RegExp('%pronoun_objective%', 'g');
  const replacementFirstName = new RegExp('%first_name%', 'g');
  const replacementActionPast = new RegExp('%subjective_action_past%', 'g');
  const replacementActionPresent = new RegExp('%subjective_action_present%', 'g');
  const replacementMonth = new RegExp('%current_month%', 'g');

  let updatedDesc = config.description;
  updatedDesc = updatedDesc
    .replace(replacementObjective, config.pronounSet.objective.toLowerCase())
    .replace(replacementPossessive, config.pronounSet.possessive.toLowerCase())
    .replace(replacementSubjective, config.pronounSet.subjective.toLowerCase())
    .replace(replacementFirstName, config.recipientFirstName)
    .replace(replacementActionPast, config.pronounSet.action.past)
    .replace(replacementActionPresent, config.pronounSet.action.present)
    .replace(replacementMonth, format(new Date(), 'MMMM'));

  return updatedDesc;
}
