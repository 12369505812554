type CertificateBuilderPreviewProps = {
  certificateDate: string
  description: string
  designBackgroundImagePath: string
  headlineImagePath: string
  recipientName: string
  senderName: string
}

const CertificateBuilderPreview = ({
  certificateDate,
  description,
  designBackgroundImagePath,
  headlineImagePath,
  recipientName,
  senderName,
}:CertificateBuilderPreviewProps) => {
  return (
    <article
      className="cert-builder--preview-box"
      style={ {
        backgroundImage: `url('${designBackgroundImagePath}')`,
      } }
    >
      <h1 className="cert-preview--headline">
        <img src={ headlineImagePath } />
      </h1>
      <h2 className="cert-preview--awarded-to has-text-centered">
          This Certificate is Awarded To
      </h2>
      <h3 className="cert-preview--name has-text-centered">
        {recipientName}
      </h3>
      <p className="cert-preview--description has-text-centered">
        {description}
      </p>
      <div className="cert-preview--fill-lines">
        <div className="cert-fill-line cert-fill-line--name">
          <div className="cert-fill-area--value">{' '}</div>
          <div className="cert-fill-area--label">{senderName ?? 'Name'}</div>
        </div>
        <div className="cert-fill-line cert-fill-line--date">
          <div className="cert-fill-area--value">{certificateDate}</div>
          <div className="cert-fill-area--label">Date</div>
        </div>
      </div>
    </article>
  );
};

export default CertificateBuilderPreview;
