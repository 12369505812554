import { useFormikContext } from 'formik';

import { CertificateColor, CertificateConfig } from '../types';

type CertBuilderStepColorProps = {
  colors: CertificateColor[]
  title: string
}

const CertBuilderStepColor = ({
  colors,
  title,
}:CertBuilderStepColorProps) => {

  const { setFieldValue, values } = useFormikContext<CertificateConfig>();

  return (
    <div className="cert-builder--step">
      <fieldset>
        <legend className="cert-step--title">{title}</legend>
        <div className="cert-builder--color-swatches">
          {colors.map((color, index) => (
            <div key={ index } className="cert-color-swatch">
              <input
                checked={ values.color.color === color.color }
                className="hidden-radio"
                id={ `color-${color.color}` }
                name="certiciateColor"
                onChange={ () => setFieldValue('color', color) }
                type="radio"
                value={ color.color }
              />
              <label htmlFor={ `color-${color.color}` } style={ {
                backgroundColor: `#${color.color}`,
              } }>
                <span className="is-sr-only">{color.name}</span>
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default CertBuilderStepColor;
