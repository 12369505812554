import { EmployeeCertificateResponse } from 'utils/api/employee-certificate';

type CertBuilderStepThankYouProps = {
  certificateResponse: EmployeeCertificateResponse | undefined
  onReset: () => void
  title: string
}

const CertBuilderStepThankYou = ({
  certificateResponse,
  onReset,
  title,
}:CertBuilderStepThankYouProps) => {
  return (
    <div className="cert-builder--step cert-builder--confirmation">
      <h2 className="cert-step--title">{title}</h2>
      <p>Your certificate is now downloading and can be found in your Downloads folder once complete. We will also email you a link to the certificate.</p>
      {certificateResponse && (
        <p>Download didn't start? <a href={ certificateResponse.url } rel="noreferrer" target="_blank">Click here</a> to start it manually.</p>
      )}
      <p>Want to create another certifciate?</p>
      <button
        className="button is-filled-green"
        onClick={ onReset }
        type="button"
      >
          Create Another
      </button>
    </div>
  );
};

export default CertBuilderStepThankYou;
