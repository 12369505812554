import { useFormikContext } from 'formik';

import CTASpinnerButton from 'components/buttons/button-spinner';
import FieldErrorPopup from 'components/forms/field-error-popup';
import InputField from 'components/forms/fields/input';
import FormLabel from 'components/forms/fields/label';
import SelectField from 'components/forms/fields/select';
import FormGroup from 'components/forms/form-group';

import { CertificateConfig } from '../types';

type CertBuilderStepFinishProps = {
  companySize: string
  email: string
  name: string
  setCompanySize: (value: string) => void
  setEmail: (value: string) => void
  setName: (value: string) => void
  title: string
}

const CertBuilderStepFinish = ({
  companySize,
  email,
  name,
  setCompanySize,
  setEmail,
  setName,
  title,
}:CertBuilderStepFinishProps) => {

  const { errors, touched, isSubmitting } = useFormikContext<CertificateConfig>();

  const showNameError = touched.downloadName && !!errors.downloadName;
  const showEmailError = !showNameError && touched.downloadEmail && !!errors.downloadEmail;
  const showSizeError = !showNameError && !showEmailError && touched.downloadCompanySize && !!errors.downloadCompanySize;

  return (
    <div className="cert-builder--step">
      <fieldset>
        <legend className="cert-step--title">{title}</legend>

        <p className="cert-step--description">Provide us with your email address and download a PDF of the final certificate to print out on your color printer.</p>
        
        <FormGroup>
          <FormLabel htmlFor="downloadName">Name</FormLabel>
          <InputField
            hasError={ showNameError }
            id="downloadName"
            name="downloadName"
            placeholder="First and Last"
            onChange={ e => setName(e.target.value) }
            value={ name }
          />
          {showNameError && (
            <FieldErrorPopup>{errors.downloadName}</FieldErrorPopup>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor="downloadEmail">Email</FormLabel>
          <InputField
            hasError={ showEmailError }
            id="downloadEmail"
            name="downloadEmail"
            onChange={ e => setEmail(e.target.value) }
            placeholder="name@example.com"
            type="email"
            value={ email }
          />
          {showEmailError && (
            <FieldErrorPopup>{errors.downloadEmail}</FieldErrorPopup>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor="downloadEmployeeCount">Company size</FormLabel>
          <SelectField
            hasError={ showSizeError }
            id="downloadEmployeeCount"
            name="downloadEmployeeCount"
            onChange={ e => setCompanySize(e.target.value) }
            placeholder="How many employees do you have?"
            value={ companySize }
          >
            <option value="">Select...</option>
            <option value="1-100">1-100 Employees</option>
            <option value="101-999">101-999 Employees</option>
            <option value="1000+">1000+ Employees</option>
          </SelectField>
          {showSizeError && (
            <FieldErrorPopup>{errors.downloadCompanySize}</FieldErrorPopup>
          )}
        </FormGroup>

      </fieldset>

      <CTASpinnerButton
        className="cert-builder--submit is-filled-green"
        data-section="employee of the month certificate builder download button"
        disabled={ isSubmitting }
        showSpinner={ isSubmitting }
        type="submit"
      >
        Download
      </CTASpinnerButton>

      <p className="cert-builder--disclaimer">
        By clicking <strong>Download</strong> you agree to the <a href="https://wheniwork.com/terms" rel="noreferrer" target="_blank">Terms of Service</a>, and <a href="https://wheniwork.com/privacy" rel="noreferrer" target="_blank">Privacy policies</a>.
      </p>

    </div>
  );
};

export default CertBuilderStepFinish;
