import axios from 'axios';

import EmployeeCertificateConfig from 'types/EmployeeCertificateConfig';

export type EmployeeCertificateResponse = {
  url: string
}

const rootPath = `${process.env.GATSBY_MARKETING_BACKEND_BASE_URL}/pdf-generator/employee-certificate`;

class EmployeeCertificateAPI {

  static async Generate(props: EmployeeCertificateConfig): Promise<EmployeeCertificateResponse> {
    const response = await axios.post<EmployeeCertificateResponse>(rootPath, {
      awarded_to_text: props.awardedToText,
      color: props.color,
      date: props.date,
      description: props.description,
      design_index: props.designIndex,
      employee_name: props.employeeName,
      sender_name: props.senderName,
      type: props.type,
    });
    return response.data;
  }

}

export default EmployeeCertificateAPI;
