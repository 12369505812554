import { ChangeEvent, forwardRef, HTMLProps } from 'react';
import classNames from 'classnames';


type TextAreaFieldProps = Omit<HTMLProps<HTMLTextAreaElement>, 'id' | 'onChange' | 'value' | 'as'> & {
  hasError?: boolean
  id: string
  minHeight?: string
  noBorder?: true
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  value: string | undefined
}

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  function TextAreaField(props, ref) {
    const { className, hasError, ...rest } = props;

    return (
      <textarea
        { ...rest }
        ref={ ref }
        className={ classNames('input', { 'input-has-error': !!hasError }, className) }
      />
    );
  }
);

export default TextAreaField;
