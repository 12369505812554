import { useFormikContext } from 'formik';

import FontIcon from 'components/icons';

import { CertificateConfig, CertificateType } from '../types';

type CertBuilderStepTypeProps = {
  title: string
  types: CertificateType[]
}

const CertBuilderStepType = ({
  title,
  types,
}:CertBuilderStepTypeProps) => {

  const { values, setFieldValue } = useFormikContext<CertificateConfig>();

  const handleChange = (type: CertificateType) => {
    setFieldValue('type', type);
    if (!values.hasCustomDescription) {
      setFieldValue('description', type.defaultDescription);
    }
  };

  const typeDiff = types.sort((a, b) => a.name < b.name ? -1 : 0);
  
  return (
    <div className="cert-builder--step">
      <fieldset>
        <legend className="cert-step--title">{title}</legend>
        <div className="cert-builder--type-selections">
          {typeDiff.map(type => (
            <div key={ type.key } className="cert-type-selection">
              <input
                checked={ values.type.key === type.key }
                className="hidden-radio"
                id={ `certType-${type.key}` }
                name="certificateType"
                onChange={ () => handleChange(type) }
                type="radio"
                value={ type.key }
              />
              <label htmlFor={ `certType-${type.key}` }>
                {type.name}
                <FontIcon className="cert-type-selection--checkmark" icon="checkmark" aria-hidden={ true } />
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default CertBuilderStepType;
