import { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import Faqs from 'components/accordion/faq';
import CertificateBuilder from 'components/forms/certificate-builder/certificate-builder';
import YoastSEO from 'components/globals/yoast';

import './styles/employee-of-the-month-certificate.scss';

const EmployeeCertificatePage = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "employee-of-the-month-certificate" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            content
            acf {
              faq {
                answer
                question
              }
              certificate_builder {
                certificate_types {
                  key
                  name
                  default_description
                  image {
                    source_url
                  }
                }
                identities {
                  key
                  objective_term
                  possessive_term
                  subjective_term
                  action_past_tense
                  action_present_tense
                }
                step_titles {
                  design
                  color
                  type
                  recipient
                  description
                  download
                  sender_name_date
                  confirmation
                }
                designs {
                  index
                  color_4a5275 {
                    source_url
                  }
                  color_5e2e28 {
                    source_url
                  }
                  color_66775e {
                    source_url
                  }
                  color_545454 {
                    source_url
                  }
                  color_928950 {
                    source_url
                  }
                }
              }
              employee_of_the_month_certificate {
                default_page_content {
                  title
                  sub_title
                  certificate {
                    source_url
                  }
                  button_text
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node;
  const pageContent = pageContext.content;
  const defaultPageContent = pageContext.acf.employee_of_the_month_certificate.default_page_content;
  const faqContent = pageContext.acf.faq;
  const certBuilderConfig = pageContext.acf.certificate_builder;

  const [showIntro, setShowIntro] = useState(true);

  const certDesigns = certBuilderConfig.designs.reduce((accum, curr) => {
    accum[curr.index] = curr;
    return accum;
  }, {});

  return (
    <Layout>
      <YoastSEO { ...pageContext.yoast_head_json } />
      <div className="EOTMC-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        {showIntro ? (
          <header className="introduction-header">
            <div className="container">
              <p className="subheadline">{defaultPageContent.title}</p>
              <h1 className="cert-intro--title headline">{defaultPageContent.sub_title}</h1>
              <div
                className="cert-intro--get-started-container"
                data-section="certificateBuilder"
                style={ { 
                  backgroundImage: `url('${defaultPageContent.certificate.source_url}')`,
                } }
              >
                <button
                  className="button is-filled-green cert-intro--start-button"
                  type="button"
                  onClick={ () => setShowIntro(false) }
                >
                  {defaultPageContent.button_text}
                </button>
              </div>
            </div>
          </header>
        ) : (
          <CertificateBuilder
            certificateTypes={ certBuilderConfig.certificate_types.map(type => ({
              defaultDescription: type.default_description,
              image: type.image,
              key: type.key,
              name: type.name,
            })) }
            designs={ certDesigns }
            identities={ certBuilderConfig.identities.map(identity => ({
              action: {
                past: identity.action_past_tense,
                present: identity.action_present_tense,
              },
              key: identity.key,
              objective: identity.objective_term,
              possessive: identity.possessive_term,
              subjective: identity.subjective_term,
            })) }
            stepTitles={ certBuilderConfig.step_titles }
          />
        )}

        <section className="section">
          <div className="container">
            <div className="columns">
              <p
                className="conntent column is-half is-three-fifths is-offset-one-fifth"
                dangerouslySetInnerHTML={ { __html: pageContent } }
              />
            </div>
          </div>
        </section>

        <section className="faq-section section" data-section="empoyee-certification-faq">
          <Faqs faq={ faqContent } />
        </section>
      </div>
    </Layout>
  );

};

export default EmployeeCertificatePage;
