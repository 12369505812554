import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import FieldErrorPopup from 'components/forms/field-error-popup';
import InputField from 'components/forms/fields/input';
import FormLabel from 'components/forms/fields/label';
import SelectField from 'components/forms/fields/select';
import FormGroup from 'components/forms/form-group';

import { CertificateBuilderPronounSet, CertificateConfig } from '../types';

type CertBuilderStepRecipientProps = {
  pronounSets: CertificateBuilderPronounSet[]
  setNextDisabled: (disabled: boolean) => void
  title: string
}

const CertBuilderStepRecipient = ({
  pronounSets,
  setNextDisabled,
  title,
}:CertBuilderStepRecipientProps) => {

  const { errors, values, setFieldValue } = useFormikContext<CertificateConfig>();

  useEffect(() => {
    setNextDisabled(!!errors.recipientFirstName || !!errors.recipientFullName);
  }, [errors]);

  return (
    <div className="cert-builder--step">
      <h2 className="cert-step--title">{title}</h2>
      <div className="cert-builder--identity">
        <div className="identity-names">
          <div className="identity-names--name first-name">
            <FormGroup>
              <FormLabel htmlFor="recipientFirstName">First name</FormLabel>
              <InputField
                hasError={ !!errors.recipientFirstName }
                id="recipientFirstName"
                onChange={ e => {
                  const value = e.target.value;
                  setFieldValue('recipientFullName', `${value} ${values.recipientLastName}`);
                  setFieldValue('recipientFirstName', value);
                } }
                value={ values.recipientFirstName }
              />
              {errors.recipientFirstName && (
                <FieldErrorPopup>{errors.recipientFirstName}</FieldErrorPopup>
              )}
            </FormGroup>
          </div>
          <div className="identity-names--name last-name">
            <FormGroup>
              <FormLabel htmlFor="recipientLastName">Last name</FormLabel>
              <InputField
                id="recipientLastName"
                onChange={ e => {
                  const value = e.target.value;
                  setFieldValue('recipientFullName', `${values.recipientFirstName} ${value}`);
                  setFieldValue('recipientLastName', value);
                } }
                value={ values.recipientLastName }
              />
            </FormGroup>
          </div>
          <div className="identity-names--validation invalid">
            {errors.recipientFullName}
          </div>
        </div>

        <FormLabel htmlFor="recipientIdentity">Pronoun</FormLabel>
        <SelectField
          id="recipientIdentity"
          name="recipientIdentity"
          onChange={ e => {
            const key = e.target.value;
            const set = pronounSets.find(set => set.key === key);
            if (set) {
              setFieldValue('pronounSet', set);
            }
          } }
          value={ values.pronounSet.key }
        >
          {pronounSets.map(set => (
            <option key={ set.key } value={ set.key }>
              {set.objective}/{set.possessive}/{set.subjective}
            </option>
          ))}
        </SelectField>
      </div>
    </div>
  );
};

export default CertBuilderStepRecipient;
