import InputField from 'components/forms/fields/input';
import FormLabel from 'components/forms/fields/label';

type CertBuilderStepSenderProps = {
  date: string
  name: string
  onDateUpdate: (value: string) => void
  onNameUpdate: (value: string) => void
  title: string
}

const CertBuilderStepSender = ({
  date,
  name,
  onDateUpdate,
  onNameUpdate,
  title,
}:CertBuilderStepSenderProps) => {
  return (
    <div className="cert-builder--step">
      <h2 className="cert-step--title">{title}</h2>

      <div className="cert-builder--sender-info">
        <div>
          <FormLabel htmlFor="senderName">Your name</FormLabel>
          <InputField
            id="senderName"
            onChange={ e => onNameUpdate(e.target.value) }
            placeholder="First and Last"
            value={ name }
          />
        </div>
        <div>
          <FormLabel htmlFor="certificateDate">Date</FormLabel>
          <InputField
            id="certificateDate"
            onChange={ e => onDateUpdate(e.target.value) }
            placeholder="MM/DD/YYYY"
            value={ date }
          />
        </div>
      </div>
    </div>
  );
};

export default CertBuilderStepSender;
