import { useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import API from 'utils/api';
import EmployeeCertificateAPI, { EmployeeCertificateResponse } from 'utils/api/employee-certificate';

import './index.scss';
import CertificateBuilderPreview from './preview';
import {
  StepColor,
  StepDescription,
  StepDesign,
  StepFinish,
  StepRecipient,
  StepSender,
  StepThankYou,
  StepType,
} from './steps';
import {
  CertificateBuilderPronounSet,
  CertificateBuilderStepTitles,
  CertificateColor,
  CertificateConfig,
  CertificateDesigns,
  CertificateType,
} from './types';
import generateDescription from './util/generate-description';

type FormSchema = Pick<CertificateConfig, 'downloadCompanySize' | 'downloadEmail' | 'downloadName' | 'recipientFirstName' | 'recipientFullName'>

type CertificateBuilderProps = {
  certificateTypes: CertificateType[]
  designs: CertificateDesigns
  identities: CertificateBuilderPronounSet[]
  stepTitles: CertificateBuilderStepTitles
}

const totalNumberSteps = 7;

const colorNameMap: Record<string, string> = {
  '4a5275': 'Lilac',
  '5e2e28': 'Burgendy',
  '66775e': 'Forest Green',
  '928950': 'Gold',
  '545454': 'Ash',
};

const defaultColor = '4a5275';

const schema: yup.Schema<FormSchema> = yup.object({
  downloadCompanySize: yup.string().required('Please choose a company size'),
  downloadEmail: yup.string().email('Must be a valid email').required('Email is required'),
  downloadName: yup.string().matches(/^\w+\s\w+/, 'Full name required').required('Please enter your full name'),
  recipientFirstName: yup.string().min(1).required('First name is required'),
  recipientFullName: yup.string().max(25, 'Full name must be 25 characters or less').required(),
});

const CertificateBuilder = (props:CertificateBuilderProps) => {

  const colors = useMemo((): CertificateColor[] => {
    return Object.keys(colorNameMap).map(key => ({
      color: key,
      name: colorNameMap[key],
    }));
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [certificateResponse, setCertificateResponse] = useState<EmployeeCertificateResponse>();

  const currentStepName = () => {
    if (currentStep === 1) return 'enteringEmployeeCertBuilder';
    if (currentStep === 2) return 'designStep';
    if (currentStep === 3) return 'colorStep';
    if (currentStep === 4) return 'typeStep';
    if (currentStep === 5) return 'recipientStep';
    if (currentStep === 6) return 'descriptionStep';
    if (currentStep === 7) return 'presenterStep';
    if (currentStep === 8) return 'downloadStep';
  };

  const resetFormFn = useRef<() => void>();

  const defaultType = useMemo(() => {
    return props.certificateTypes.find(type => type.key === 'employee-of-the-month') ?? props.certificateTypes[0];
  }, [props.certificateTypes]);

  const emptyCertificateConfig: CertificateConfig = useMemo(() => ({
    certificateDate: format(new Date(), 'LL/dd/yyyy'),
    color: colors.find(color => color.color === defaultColor) ?? colors[0],
    description: defaultType.defaultDescription,
    design: 0,
    downloadCompanySize: '',
    downloadEmail: '',
    downloadName: '',
    hasCustomDescription: false,
    pronounSet: props.identities.find(type => type.key === 'neutral') ?? props.identities[0],
    recipientFirstName: 'John',
    recipientLastName: 'Doe',
    recipientFullName: 'John Doe',
    senderName: 'Name',
    type: defaultType,
  }), []);

  const getStepContainerClass = (stepNumber: number): string => {
    return `cert-builder--step-container${stepNumber === currentStep ? ' is-active-step' : ''}`;
  };

  const handleSubmit = async (data: CertificateConfig) => {
    // ensure the description is generated
    data.description = !data.hasCustomDescription ? generateDescription(data) : data.description;

    const response = await EmployeeCertificateAPI.Generate({
      awardedToText: 'THIS CERTIFICATE IS AWARDED TO',
      color: data.color.color,
      date: data.certificateDate,
      description: data.description,
      designIndex: data.design,
      employeeName: `${data.recipientFirstName} ${data.recipientLastName}`,
      senderName: data.senderName,
      type: data.type.key,
    });

    setCertificateResponse(response);
    setHasCompleted(true);

    // trigger the download
    window.open(response.url, '_blank');

    await API.vero({
      companySize: data.downloadCompanySize,
      email: data.downloadEmail,
      fullName: data.downloadName,
      pdfDownloadUrl: response.url,
    }, 'employeeCertificateDownload');

    // @ts-expect-error adroll is defined in production only
    // eslint-disable-next-line no-undef
    Boolean(window.__adroll) ? __adroll.record_user({ 'adroll_segments': '45e57456' }) : console.log('ERROR: adroll record_user error');
  };

  const handleReset = () => {
    if (resetFormFn.current) {
      resetFormFn.current();
      setCurrentStep(1);
      setCertificateResponse(undefined);
      setHasCompleted(false);
    }
  };

  return (
    <Formik
      initialValues={ emptyCertificateConfig }
      onSubmit={ handleSubmit }
      validationSchema={ schema }
    >
      {({
        setFieldValue,
        values,
        resetForm,
      }) => {

        resetFormFn.current = resetForm;

        return (
          <section className={ `cert-builder design-${values.design} type-${values.type.key}` }>
            <div className="container">
              <div className="cert-builder--columns">
                <div className="cert-builder--preview-container">
                  <CertificateBuilderPreview
                    certificateDate={ values.certificateDate }
                    description={ values.hasCustomDescription ? values.description : generateDescription(values) }
                    designBackgroundImagePath={ props.designs[values.design][`color_${values.color.color.toLowerCase()}`].source_url }
                    headlineImagePath={ values.type.image.source_url }
                    recipientName={ `${values.recipientFirstName} ${values.recipientLastName}` }
                    senderName={ values.senderName }
                  />
                </div>
                <div className="cert-builder--steps-column" data-section={ currentStepName() }>
                  <div className="steps-column--steps">
                    {hasCompleted ? (
                      <div className="cert-builder--step-container is-active-step">
                        <StepThankYou
                          certificateResponse={ certificateResponse }
                          onReset={ handleReset }
                          title={ props.stepTitles.confirmation }
                        />
                      </div>
                    ) : (
                      <Form>
                        <div className={ getStepContainerClass(1) }>
                          <StepDesign
                            designs={ props.designs }
                            title={ props.stepTitles.design }
                          />
                        </div>
                        <div className={ getStepContainerClass(2) }>
                          <StepColor
                            colors={ colors }
                            title={ props.stepTitles.color }
                          />
                        </div>
                        <div className={ getStepContainerClass(3) }>
                          <StepType
                            title={ props.stepTitles.type }
                            types={ props.certificateTypes }
                          />
                        </div>
                        <div className={ getStepContainerClass(4) }>
                          <StepRecipient
                            pronounSets={ props.identities }
                            setNextDisabled={ setNextStepDisabled }
                            title={ props.stepTitles.recipient }
                          />
                        </div>
                        <div className={ getStepContainerClass(5) }>
                          <StepDescription
                            generatedDescription={ values.hasCustomDescription ? values.description : generateDescription(values) }
                            title={ props.stepTitles.description }
                          />
                        </div>
                        <div className={ getStepContainerClass(6) }>
                          <StepSender
                            date={ values.certificateDate }
                            name={ values.senderName }
                            onDateUpdate={ value => setFieldValue('certificateDate', value) }
                            onNameUpdate={ value => setFieldValue('senderName', value) }
                            title={ props.stepTitles.sender_name_date }
                          />
                        </div>
                        <div className={ getStepContainerClass(7) }>
                          <StepFinish
                            companySize={ values.downloadCompanySize }
                            email={ values.downloadEmail }
                            name={ values.downloadName }
                            setCompanySize={ value => setFieldValue('downloadCompanySize', value) }
                            setEmail={ value => setFieldValue('downloadEmail', value) }
                            setName={ value => setFieldValue('downloadName', value) }
                            title={ props.stepTitles.download }
                          />
                        </div>
                      </Form>
                    )}
                  </div>
                  {!hasCompleted && (
                    <div className="steps-column--navigation">
                      <button
                        className={ `button nav-button-previous ${currentStep % totalNumberSteps === 1 ? 'is-invisible' : ''}` }
                        disabled={ currentStep % totalNumberSteps === 1 }
                        onClick={ () => setCurrentStep(curr => curr - 1) }
                        type="button"
                      >
                          Previous Step
                      </button>
                      <button
                        className={ `button nav-button-next is-filled-green ${currentStep % totalNumberSteps === 0 ? 'is-invisible' : ''}` }
                        disabled={ currentStep % totalNumberSteps === 0 || nextStepDisabled }
                        onClick={ () => setCurrentStep(curr => curr + 1) }
                        type="button"
                      >
                        Next Step
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        );
      }}
    </Formik>
  );
};

export default CertificateBuilder;
