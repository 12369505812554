import { useFormikContext } from 'formik';

import TextAreaField from 'components/forms/fields/textarea';

import { CertificateConfig } from '../types';

const maxLength = 480;

type CertBuilderStepDescriptionProps = {
  generatedDescription: string
  title: string
}

const CertBuilderStepDescription = ({
  generatedDescription,
  title,
}:CertBuilderStepDescriptionProps) => {
  
  const { values, setFieldValue } = useFormikContext<CertificateConfig>();

  const handleReset = () => {
    setFieldValue('description', values.type.defaultDescription);
    setFieldValue('hasCustomDescription', false);
  };

  return (
    <div className="cert-builder--step">
      <h2 className="cert-step--title">{title}</h2>
      <TextAreaField
        id="certificateDescription"
        maxLength={ maxLength }
        onChange={ e => {
          setFieldValue('description', e.target.value);
          setFieldValue('hasCustomDescription', true);
        } }
        value={ generatedDescription }
      />
      <div className="cert-builder-description--actions">
        <button onClick={ handleReset } type="button">Reset to Default</button>
        <p className={ `cert-builder-description--char-count ${maxLength - generatedDescription.length <= 0 ? 'invalid' : ''}` }>
          {maxLength - generatedDescription.length} characters remaining
        </p>
      </div>
    </div>
  );
};

export default CertBuilderStepDescription;
