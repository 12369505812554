import { useFormikContext } from 'formik';

import { CertificateConfig, CertificateDesigns } from '../types';

type CertBuilderStepDesignProps = {
  designs: CertificateDesigns
  title: string
}

const CertBuilderStepDesign = ({
  designs,
  title,
}:CertBuilderStepDesignProps) => {

  const { values, setFieldValue } = useFormikContext<CertificateConfig>();

  return (
    <div className="cert-builder--step">
      <fieldset>
        <legend className="cert-step--title">{title}</legend>

        <div className="cert-builder--design-tiles">
          {Object.keys(designs).map(key => {
            const design = designs[key];
            const url = design[`color_${values.color.color.toLowerCase()}`].source_url;
            return (
              <div key={ key } className="cert-design-tile">
                <input
                  checked={ values.design === Number(key) }
                  className="hidden-radio"
                  id={ `design-${key}` }
                  name="certiciateDesign"
                  onChange={ () => setFieldValue('design', Number(key)) }
                  type="radio"
                  value={ Number(key) }
                />
                <label htmlFor={ `design-${key}` } style={ {
                  backgroundImage: `url('${url}')`,
                } }>
                  <span className="is-sr-only">Design {Number(key) + 1}</span>
                </label>
              </div>
            );
          })}
        </div>

      </fieldset>
    </div>
  );
};

export default CertBuilderStepDesign;
